import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import { UnrevealedEV, Ring, Scout } from "../../../../assets/images";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import {
    getWalletInfo,
    IWalletInfoSliceState,
} from "../../../../state/wallet-info-state";
import placeAuctionBidAsyncAction from "../../../../state/wallet-info-state/actions/placeAuctionBidAsyncAction";
import claimAuctionGoldAsyncAction from "../../../../state/wallet-info-state/actions/claimAuctionGoldAsyncAction";

import "./LayoutAuctionPanel.scss";

const LayoutAuctionPanel: React.FC = () => { 
    const dispatch = useAppDispatch();
    const [bidInputAmount, setBidInputAmount] = React.useState();
    const [bidPlaceHolder, setBidPlaceHolder] = React.useState("Your bid is 0 $GOLD")
    const [highestBid, setHighestBid] = React.useState(2000);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [now, setNow] = React.useState(0);

    React.useEffect(() => {  
        let interval = window.setInterval(() => {                        
            setNow(Math.floor(Date.now() / 1000));                   
        }, 1000);
        return () => clearInterval(interval);
    });

    const getDaysTillEnd = () => {
        let targetDate = parseInt(process.env.REACT_APP_AUCTION_END_DATE || "0");        
        let timeDiff = Math.max(targetDate - now, 0);
        let daysLeft = Math.floor(timeDiff / 86400);
        if (daysLeft < 10)
            return `0${daysLeft}`
        else
            return daysLeft.toString();
    }

    const getHoursTillEnd = () => {
        let targetDate = parseInt(process.env.REACT_APP_AUCTION_END_DATE || "0");       
        let timeDiff = Math.max(targetDate - now, 0);
        let daysLeft = Math.floor((timeDiff % 86400) / 3600);
        if (daysLeft < 10)
            return `0${daysLeft}`
        else
            return daysLeft.toString();
    }

    const getMinutesTillEnd = () => {
        let targetDate = parseInt(process.env.REACT_APP_AUCTION_END_DATE || "0");       
        let timeDiff = Math.max(targetDate - now, 0);
        let daysLeft = Math.floor((timeDiff % 3600) / 60);
        if (daysLeft < 10)
            return `0${daysLeft}`
        else
            return daysLeft.toString();
    }

    const getSecondsTillEnd = () => {
        let targetDate = parseInt(process.env.REACT_APP_AUCTION_END_DATE || "0");       
        let timeDiff = Math.max(targetDate - now, 0);
        let daysLeft = Math.floor((timeDiff % 60) / 1);
        if (daysLeft < 10)
            return `0${daysLeft}`
        else
            return daysLeft.toString();
    }

    const { 
        currentGold, 
        provider,
        highestAuctionBid,
        auctionBid
    } = useAppSelector(
        getWalletInfo()
    ) as IWalletInfoSliceState;

    React.useEffect(() => {
        if (auctionBid) {
            setBidPlaceHolder(`Your bid is ${auctionBid} $GOLD.`)
            setBidInputAmount(undefined)
        }
    }, [auctionBid]);

    React.useEffect(() => {
        if (highestAuctionBid) {
            setHighestBid(highestAuctionBid);
            // setBidInputAmount(highestAuctionBid + 1)
        }
    }, [highestAuctionBid]);

    const onPlaceAuctionBidClick = () => {
        setErrorMsg("");
        if (!bidInputAmount || isNaN(bidInputAmount)) {
            setErrorMsg("Please enter a valid number in the bid section.");
            return;
        }
        if (bidInputAmount + auctionBid <= highestBid) {
            setErrorMsg(`Your bid is too low. \nTry and place a bid higher than ${highestBid} $GOLD.`);
            return;
        }
        if (bidInputAmount > currentGold) {
            setErrorMsg(`You do not have enough gold to place the bid. \n Try lowering your bid.`);
            return;
        }
        dispatch(
            placeAuctionBidAsyncAction({provider, bid: bidInputAmount + auctionBid})
        );
    };

    const onClaimGoldClick = () => {
        setErrorMsg("");
        dispatch(
            claimAuctionGoldAsyncAction({provider})
        );
    }

    const handleInput = (e: string) => {
        if (isNaN(parseInt(e))) {
            setBidInputAmount(undefined);
            return;
        }
        // @ts-ignore
        setBidInputAmount(parseInt(e))
    }

  return (
    <div className="auction-component-container flex column justify-start align-center xl-mt w-all">
        <EVGenericText text={"New Auction – The Scout"} type={"p"} size={"large"} extraClassNames="l-mt l-mb" />
        <img src={Scout} alt="Scout"/>
        <EVGenericText
            text={"Minimum bid: 2000 $Gold \n\nThe highest bidder will win the very first Viking, aka The Scout, the guest of honor and #1501 in our Genesis collection. \n\nBut wait, there will also be a second winner! The participant whose bid stays the leading one for the longest time will get to be the proud owner of EV's first comics series cover in NFT form.\n\nOnce the countdown ends, the auction will be open for 48 hours. \n\nGet your $Gold ducks in a row!"}
            extraClassNames={"l-mr l-ml m-mt text-center"}
            type="p"
        />

        {<EVGenericText
            text={`Highest bid: ${highestBid} $GOLD`}           
            type="p"
        />}
        {<EVGenericText
            text={`Your bid: ${auctionBid} $GOLD`}           
            type="p"
        />}

        {
            <div className="auction-section m-mt m-mr m-ml">
                <input 
                    type="text"
                    value={bidInputAmount}
                    placeholder={bidPlaceHolder}
                    onChange={(e) => handleInput(e.target.value)}               
                />
                <EVGenericButton
                    text={auctionBid === 0 ? "Place bid" : "Add to bid"}           
                    disabled={false}
                    isDark={true}
                    cb={() => onPlaceAuctionBidClick()}
                />
            
            </div>
        }
        {/*
            auctionBid === highestBid ? 
                <EVGenericText
                    text={`Congratulations, you won the auction!`}           
                    type="p"
                />
            :
                auctionBid > 0 ?                
                <>
                    <EVGenericText
                        text={`You did not win the auction. Claim back your gold here.`}           
                        type="p"
                        extraClassNames="m-mt"
                    />
                    <EVGenericButton
                        text={`Claim ${auctionBid} $GOLD back`}           
                        disabled={false}
                        isDark={true}
                        cb={() => onClaimGoldClick()}
                        extraClassNames="m-mt"
                    />
                </>
                :
                <EVGenericText
                    text={`You did not win the auction.\nStay tuned for the next auction!`}           
                    type="p"
                    extraClassNames="m-mt"
                />
*/        }

        {/*
            auctionBid > 0 ?
                <>
                    <EVGenericText
                        text={`Claim back your gold here from the ring auction.`}           
                        type="p"
                        extraClassNames="m-mt"
                    />
                    <EVGenericButton
                        text={`Claim ${auctionBid} $GOLD back`}           
                        disabled={false}
                        isDark={true}
                        cb={() => onClaimGoldClick()}
                        extraClassNames="m-mt"
                    />
                </>                
            :
                null
*/}
        {
            errorMsg !== "" ?
                <EVGenericText
                    text={errorMsg}           
                    type="p"
                    extraClassNames="m-mt text-center"
                /> 
            :
                null
        }

        <EVGenericText text={"Auction ends in"} type={"p"} extraClassNames={"m-mt"} /> 
        <EVGenericText text={`${getDaysTillEnd()}:${getHoursTillEnd()}:${getMinutesTillEnd()}:${getSecondsTillEnd()}`} type={"p"} size={"large"} extraClassNames={"s-mt m-mb"} />  
    </div>
  );
};

export default LayoutAuctionPanel;
